import { useEffect, useState } from "react";
import React from "react";
import { Grid, IconButton, Typography } from "@material-ui/core";
import { useFirestore } from "react-redux-firebase";
import DashboardCard from "../../general/DashboardCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ICON_STYLE } from "../../../../../utils/constants";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { UserRole } from "../../../../../firestore/models";

const PluginUpdatesV2 = () => {
  const firestore = useFirestore();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(true);
  const [releases, setReleases] = useState<Array<Record<string, any>> | null>(
    null
  );

  const [releasesCurrentYear, setReleasesCurrentYear] = useState<number | null>(
    null
  );

  useEffect(() => {
    setLoading(true);
    firestore
      .collectionGroup(`release`)
      .orderBy("releaseDate", "desc")
      .limit(6)
      .get()
      .then((results) => {
        const resultArray: Array<Record<string, any>> = [];
        results.forEach((result) => {
          const data = result.data();
          const pluginId = result.ref.parent.parent?.id ?? null;
          if (pluginId !== null) {
            resultArray.push({ ...data, pluginId: pluginId });
          }
        });
        setReleases(resultArray);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    firestore
      .collectionGroup(`release`)
      .orderBy("releaseDate", "desc")
      .where(
        "releaseDate",
        ">=",
        moment().set("month", 0).set("date", 0).toDate()
      )
      .get()
      .then((results) => {
        console.log(
          moment(
            results.docs[0].data()?.releaseDate?.toDate() ?? new Date()
          ).format("DD.MM.YYYY")
        );
        console.log(
          moment(
            results.docs[results.size - 1].data()?.releaseDate?.toDate() ??
              new Date()
          ).format("DD.MM.YYYY")
        );
        setReleasesCurrentYear(results.size);
      });
  }, []);

  return (
    <DashboardCard
      title={
        releasesCurrentYear !== null
          ? `Letzte Updates (${moment().year()}: ${releasesCurrentYear} Updates)`
          : `Letzte Updates`
      }
      theme="plugin"
      loading={loading}
      role={UserRole.Developer}
    >
      <div
        style={{
          width: "100%",
          minWidth: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        <Grid container spacing={2} alignItems={"center"}>
          {releases !== null &&
            releases
              .sort((v1, v2) => {
                if (v1.releaseDate > v2.releaseDate) {
                  return -1;
                } else if (v1.releaseDate < v2.releaseDate) {
                  return 1;
                } else {
                  return 0;
                }
              })
              .map((release) => {
                return (
                  <>
                    <Grid item xs={3}>
                      <img
                        title={`${release.pluginId}`}
                        // className="bg-contain h-72 mx-auto"
                        style={{ width: "80%", borderRadius: 50 }}
                        src={`https://ws-cdn.de/plugins/icons/${release.pluginId.replace(
                          "ws5_",
                          "ws_"
                        )}.svg`}
                      />
                    </Grid>
                    <Grid item xs={7}>
                      <Grid container spacing={1} alignItems={"center"}>
                        <Grid item xs={5} md={12} lg={5}>
                          <Typography
                            style={{
                              color: "#FFF",
                              fontSize: 12,
                              fontFamily: "Roboto",
                              fontWeight: "300",
                            }}
                          >
                            {`Version:`}
                          </Typography>
                        </Grid>
                        <Grid item xs={6} md={12} lg={6}>
                          <Typography
                            style={{
                              color: "#FFF",
                              fontSize: 12,
                              fontFamily: "Roboto",
                              fontWeight: "500",
                            }}
                          >
                            {`${release?.version ?? `n/a`}`}
                          </Typography>
                        </Grid>
                        <Grid item xs={5} md={12} lg={5}>
                          <Typography
                            style={{
                              color: "#FFF",
                              fontSize: 12,
                              fontFamily: "Roboto",
                              fontWeight: "300",
                            }}
                          >
                            {`Veröffentlicht:`}
                          </Typography>
                        </Grid>
                        <Grid item xs={6} md={12} lg={6}>
                          <Typography
                            style={{
                              color: "#FFF",
                              fontSize: 12,
                              fontFamily: "Roboto",
                              fontWeight: "500",
                            }}
                          >
                            {`${
                              moment(release?.releaseDate.toDate()).format(
                                `DD.MM.YYYY HH:mm`
                              ) ?? `n/a`
                            }`}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={1}>
                      <IconButton
                        size="small"
                        onClick={() => {
                          navigate(`/developer/plugins/${release.pluginId}`);
                        }}
                      >
                        <FontAwesomeIcon
                          size="1x"
                          icon={[ICON_STYLE, "arrow-up-right-from-square"]}
                        />
                      </IconButton>
                    </Grid>
                  </>
                );
              })}
        </Grid>
      </div>
    </DashboardCard>
  );
};

export default PluginUpdatesV2;
